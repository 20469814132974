import React, { useState, useEffect, useRef } from 'react'
import './App.css'
import CountUp from 'react-countup'
import { Provider, Relative, Button, Flex, Box, Heading, Text, Toolbar } from 'rebass'
import theme from './theme/base'
import { ThemeProvider, Link, AspectRatio, Divider, Badge } from 'theme-ui'
// import { LoremIpsum } from 'react-lorem-ipsum'
import { FaLinkedin, FaEnvelope, FaGithubAlt } from 'react-icons/fa'
import EmailForm from './components/EmailForm'

// import { ReactComponent as Logo } from './assets/icon.svg'

// const getResponses = async () => {
//   const res = await fetch('https://script.google.com/macros/s/AKfycbyD6a_cjTIko_ZrKwEBOOYIvV63FlkOnTvatLWaa22Tdv4bqPwp/exec')
//   const data = await res.json()
//   return (data && data.values) || []
// }

// const loadResume = async () => {
//   const res = await fetch('/resume.html')
//   const data = await res.text()
//   console.log('HTML', data)
//   return data
// }

export default props => {
  // const [count, setCount] = useState(0)
  // useEffect(() => {
  //   async function load() {
  //     const resume = await loadResume()
  //     cvRef.current.innerHTML = resume

  //     const responses = await getResponses()
  //     setCount(responses.length + 1)  
  //   }
  //   load().catch(err => console.error(err))
  // }, [])
  const cvRef = useRef()
  return (
    <ThemeProvider theme={theme}>
      {/* <Logo 
        className='logo-animated'
        style={{ 
          width: 82, 
          height: 82, 
          position: 'fixed', 
          top: 16, left: 16
        }}/> */}
      <div ref={cvRef} style={{ margin: 'auto' }}></div>
      <Flex height={[ 'auto', '98vh', '98vh' ]} flexWrap='wrap' mb={'4vh'}>
        <Flex
          bg='primary'
          width={[ 1, 1, 1/3 ]}
          justifyContent='flex-end'
          flexDirection='column'
          p={[3, 4, 0]}>

          <Box textAlign={'center'}>
            <Flex p={3}>
              <Box width={1/3} px={3} textAlign={'right'}>
                <Link href='https://www.linkedin.com/in/olgakornilova/' target='_blank'><Heading fontSize={[ 2, 2, 3 ]} target='_blank' textALign='left'><FaLinkedin color='white' /></Heading></Link>
              </Box>
              <Box width={1/3} px={3}  textAlign={'center'}>
                <Link href='mailto:me@olgakornilova.com'><Heading fontSize={[ 2, 2, 3 ]} textALign='right'><FaEnvelope color='white'/></Heading></Link> 
              </Box>            
              <Box width={1/3} px={3}  textAlign={'left'}>
                <Link href='https://github.com/ili-ili' target='_blank'><Heading fontSize={[ 2, 2, 3 ]} textALign='right'><FaGithubAlt color='white' /></Heading></Link> 
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Flex width={[ 1, 1, 2/3 ]} height={[ 'auto', 'auto', '100vh' ]} p={[5]}>
          <Box alignSelf='center' p={['auto']}>
            {/* <Heading width={[ 1, 1, '80%' ]} fontSize={[ 5, 5, 6 ]} pb={3} textAlign='left'>Degree project</Heading>
            <Text textAlign='left' width={[ 1, 1, '80%' ]} fontSize={[ 1, 2, 2 ]} pb={5}>
              There is a better way to document a specification of a digital product! I am working on this task as my degree project research.
            </Text> */}

            <Heading fontSize={[ 5, 5, 6 ]} pb={4}>Hey, I'm Olga! </Heading>
            <Heading width={[ 1, 1, '80%' ]} fontSize={3} pb={5} pl={2}>
              My most vital skills are in the intersection of <mark> design </mark> and <mark> technology</mark> and my passion is translating research findings into <mark> actionable </mark> user stories.
            </Heading>
            <Flex p={3}>
              <Box width={1} pt={3} textAlign={'left'}>
                <Link width={['100%', '300px', '320px']} mb={[ '14px', '0', '0' ]}  variant='button' style={{ display: 'inline-block' }} primary download href='/Kornilova Olga - resume.pdf' p={14} borderRadius={0}>Download resume</Link>
                <Link width={['100%', '300px', '320px']} download href='/Kornilova Olga - portfolio.pdf' p={14} borderRadius={0}>Download portfolio</Link>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Flex>
      {/* <AspectRatio
        ratio={16/9}
        alignItems='center'
        sx={{
          p: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'text',
          bg: 'secondary',
          textAlign: 'center'
        }}>
        <Flex color='white' alignSelf='flex-start'>SOLUTION</Flex>
        <Flex bg='white' flexDirection='column'>
          <Heading fontSize={[ 5, 6, 6 ]} heigth={'100%'}>
            <LoremIpsum p={1} avgSentencesPerParagraph={1} /> 
            Reseach: Functional Requirements in Software Product Development Process
            holistic <mark>visual specification</mark> of digital product for <mark>efficient collaboration</mark> of developers , designers and clients
          </Heading>
        </Flex>
      </AspectRatio> */}
    {/* <Flex
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      height={['auto', '70vw', '50vw']}
      p={[2, 2, 4]}
      pb={4}>
      <Box width={['100vw', '70vw', '50vw']} m={'auto'}>
        <Heading fontSize={[ 5, 5, 6 ]} pb={4} textAlign={'center'}>Check it out! </Heading>
        <Text fontSize={[ 1, 2, 2 ]} textAlign={'center'} p={3}>My most vital skills are in the intersection of design and development and my passion is translating research findings into actionable user stories. <br/>Download PDF with some highlights of my work.</Text>
        <Box width={['100%']} m={'40px auto'} style={{textAlign: 'center'}}><Link variant='button' width={['100%', '300px', '320px']} mt={3} download href='/Portfolio_Kornilova Olga - design_code.pdf' p={14} borderRadius={0}>Download portfolio PDF</Link></Box>
      </Box>
    </Flex> */}
      {/* <Flex alignItems='center' 
        height={'30vh'} 
        bg='primary'
        justifyContent='center' p={3}>
        <Box textAlign={'center'}>
          <Flex mx={-2}>
            <Box width={1/2} px={3} textAlign={'right'}>
              <Link href='https://www.linkedin.com/in/olgakornilova/' target='_blank'><Heading fontSize={[ 2, 2, 3 ]} target='_blank' textALign='left'><FaLinkedin color='white' /></Heading></Link>
            </Box>
            <Box width={1/2} px={3}  textAlign={'left'}>
              <Link href='mailto:me@olgakornilova.com'><Heading fontSize={[ 2, 2, 3 ]} textALign='right'><FaEnvelope color='white'/></Heading></Link> 
            </Box>            
            <Box width={1/2} px={3}  textAlign={'left'}>
              <Link href='https://github.com/ili-ili' target='_blank'><Heading fontSize={[ 2, 2, 3 ]} textALign='right'><FaGithubAlt color='white' /></Heading></Link> 
            </Box>
          </Flex>
        </Box>
      </Flex> */}
    </ThemeProvider>
    )
}